const queryString = location.search;
const currentPath = location.pathname;
const params = new URLSearchParams(queryString);
const language = params.get('lang') || navigator.language || navigator.userLanguage;
const selectedLanguage = language != 'pt-BR' ? 'en-EU' : 'pt-BR';

const availablePages = ['', '/', '/home', '/project', '/resume'];


const setupMenuConfig = () => {
  $('*[data-menu]').each(function() {
    const path = $(this).data('menu');
    $(this).on('click', () => switchPage(path))
  })
}

const switchPage = (path) => {
  if (path === '/' || path === '') {
      path = '/home';
  }
  $.get(`./pages${path}.html`, (page) => {
    $('#inner-content').html(page);
    window.history.pushState({ html: page }, '', `${location.origin}${path}`)
  });
}

const switchLanguage = (lang) => {
  $.get(`/assets/locales/${lang}.json`, (translation) => {
    $( "*[data-i18n]" ).each(function () {
      const id = $(this).data('i18n');
      const text = id.split('.').reduce((previous, currentValue) => {
        return previous[currentValue]
      }, translation)
      $(this).text(text);
    });
  });
}

window.onpopstate = function(e){
  if(e.state){
    $('#inner-content').html(e.state.html);
  }
};

// application bootstrap
$(document).ready(function() {
  if(availablePages.includes(currentPath)) {
    switchPage(currentPath);
  } else {
    switchPage('/404');
  }
  switchLanguage(selectedLanguage);
  setupMenuConfig();
})